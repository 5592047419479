import {ParticipantManagementState} from "../../participant-management/interfaces/participant-management.state";
import {UnknownAction} from "redux";
import * as actionTypes from "../../../../store/actions";
import produce from "immer";
import {EvripathDashboardState} from "../interfaces/evripath-dashboard.state";
import {InstancesInterface} from "../interfaces/instances.interface";



const initialState = {
    isLoadingInstances: true,
    instances: [],
    totalInstances: 0,
    selectedInstance: null
};


const evripathDashboardReducer = (state: EvripathDashboardState = initialState, action: UnknownAction) => {
    switch ((action as any).type) {
        case  actionTypes.EVRIPATH_DASHBOARD_LOAD:
            return produce(state, draft => {
                draft.isLoadingInstances = true;
            });
        case  actionTypes.EVRIPATH_DASHBOARD_LOADED:
            return produce(state, draft => {
                draft.instances = action.instances as InstancesInterface[];
                draft.isLoadingInstances = false;
            });
        case  actionTypes.EVRIPATH_DASHBOARD_SELECT:
            return produce(state, draft => {
                draft.selectedInstance = action.instance as InstancesInterface;
            });
        case  actionTypes.EVRIPATH_DASHBOARD_UNSELECT:
            return produce(state, draft => {
                draft.selectedInstance = null;
            });

        default:
            return state;
    }
}

export default evripathDashboardReducer;
