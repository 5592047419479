import * as actionTypes from "../../../store/actions";
import produce from "immer";
import {TrialState} from "../interfaces/trial.state";
import {TrialAction} from "../interfaces/trial.action";
import {UnknownAction} from "redux";

const initialState = {
    isLoadingTrials: true,
    totalTrials: 0,
    trials: [],
    isChangingLocationDefault: false,
    isSavingFavourite: false,
    acceptingTrialId: null,
    decliningTrialId: null
};

const trialsReducer = (state: TrialState = initialState, action: UnknownAction) => {
    switch ((action as any).type) {
        case actionTypes.TRIALS_INVITES_ACCEPTING:
            return produce(state, draft => {
                draft.acceptingTrialId = (action as any).acceptingTrialId;
            });
        case actionTypes.TRIALS_INVITES_ACCEPTED:
            return produce(state, draft => {
                draft.acceptingTrialId = null;
                window.location.reload();
            });
        case actionTypes.TRIALS_INVITES_DECLINING:
            return produce(state, draft => {
                draft.decliningTrialId = (action as any).decliningTrialId;
            });
        case actionTypes.TRIALS_INVITES_DECLINED:
            return produce(state, draft => {
                draft.decliningTrialId = null;
                window.location.reload();
            });
        case actionTypes.TRIALS_TOGGLE_FAVOURITE:
            return produce(state, draft => {
                draft.isSavingFavourite = true;
            });
        case actionTypes.TRIALS_TOGGLE_FAVOURITE_CHANGED:
            return produce(state, draft => {
                const trial = draft.trials.find(t => t.id === (action as any).trialId);
                trial.isFavourite = !trial.isFavourite;
                draft.isSavingFavourite = false;
            });
        case actionTypes.TRIALS_CHANGE_TRIAL_DEFAULT_LOCATION:
            return produce(state, draft => {
                draft.isChangingLocationDefault = true;
            });
        case actionTypes.TRIALS_TRIAL_DEFAULT_LOCATION_CHANGED:
            return produce(state, draft => {
                const trial = draft.trials.find(t => t.id === (action as any).trialId);
                trial.defaultSite = (action as any).siteId;
                draft.isChangingLocationDefault = false;
            });
        case actionTypes.TRIALS_OVERVIEW_LOAD:
            return produce(state, draft => {
                draft.isLoadingTrials = true;
            });
        case actionTypes.TRIALS_OVERVIEW_LOADED:
            return produce(state, draft => {

                const trials = (action as any).trials;
                const formattedTrials = trials.map((t:any, i:number) => {
                    const filteredSites = [...t.sites.filter((s: any) => (action as any).allowedSites ? (action as any).allowedSites.map((a:any) => a.id).includes(s.siteId) : s).sort((a: any, b: any) =>  {
                        let fa = a.siteName.toLowerCase(),
                            fb = b.siteName.toLowerCase();

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    })];
                   // const firstSite = (filteredSites.length) ? filteredSites[0].siteId : null;

                    let defaultSite = null;
                    for(let i = 0;i<(action as any).siteDefaults.length;i++){
                        const f = (action as any).siteDefaults[i];
                        const json = JSON.parse(f.favouriteValue);
                        if(Number(json.trialId) === Number(t.id) && filteredSites.find(f => f.siteId === json.siteId)){
                            defaultSite = json.siteId;
                            break;
                        }
                    }

                    return {
                        id: Number(t.id),
                        name: t.trialname,
                        code: t.protocolNumber,
                        isFavourite: ((action as any).favourites && (action as any).favourites.map((f:any) => Number(f.favouriteValue)).includes(t.id)),
                        trialIdentifier: t.trialIdentifier,
                        sponsorName: t.sponsorName,
                        trialAdmin: t.trialAdmin,
                        status: t.status,
                        sitesTotal: filteredSites.length,
                        defaultSite: defaultSite ? defaultSite : null,
                        sites: filteredSites,
                        recruitmentEndDate: t.recruitmentEndDate,
                        registrationTarget: t.registrationTarget,
                        referralTarget: t.referralTarget,
                        enrolmentTarget: t.enrolmentTarget,
                        index: i
                    }
                });

                draft.trials = (trials) ? formattedTrials : [];
                draft.totalTrials = draft.trials.length;
                draft.isLoadingTrials = false;
            });
        default:
            return state;
    }
};

export default trialsReducer;







