/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddReferralLinkEventRequest } from '../models/AddReferralLinkEventRequest';
import type { CreateReferralLinkRequest } from '../models/CreateReferralLinkRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EvripathService {

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiEvripathCreateReferralLink(
requestBody?: CreateReferralLinkRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Evripath/CreateReferralLink',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiEvripathAddReferralLinkEvent(
requestBody?: AddReferralLinkEventRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Evripath/AddReferralLinkEvent',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static postApiEvripathSyncEvripathRegistration(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Evripath/SyncEvripathRegistration',
        });
    }

    /**
     * @param resultsPerPage 
     * @param pageNumber 
     * @param search 
     * @param orderBy 
     * @param sortBy 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiEvripathGetRegistrations(
resultsPerPage?: number,
pageNumber?: number,
search: string = '',
orderBy: string = '',
sortBy: string = '',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Evripath/GetRegistrations',
            query: {
                'resultsPerPage': resultsPerPage,
                'pageNumber': pageNumber,
                'search': search,
                'orderBy': orderBy,
                'sortBy': sortBy,
            },
        });
    }

}
