import React, {lazy} from 'react';
import {Route, Navigate, Routes} from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/auth-guard';
import {RoleEnum} from "../shared/enums/roleEnum";
import GuardedRoute from "../utils/route-guard/guarded.route";
import {UserPermissionTypesEnum} from "../shared/permissions/enums/user-permission-types.enum";
import {UserPermissionsEnum} from "../shared/permissions/enums/user-permissions.enum";
import PermissionRestricted from "../utils/component-guard/PermissionRestricted";
import AuthLayout from "../layout/AuthLayout";
import PublicGuard from "../utils/route-guard/public-guard";
import RegisteredGuard from "../utils/route-guard/register-gaurd";


// top level page routing
const Dashboard = Loadable(lazy(() => import('../views/dashboard/dashboard.component')));


const SingleTrial = Loadable(lazy(() => import('../views/single-trial/selectedSingleTrial')));
const Reports = Loadable(lazy(() => import('../views/reports/container/report-management.container')));

const NotificationsOverview = Loadable(lazy(() => import("../views/preferences/notifications/notificationsOverview")));
const YourDetails = Loadable(lazy(() => import('../views/preferences/profile/profile')));
const PreferredTimesContainer = Loadable(lazy(() => import('../views/preferences/preferred-times/containers/preferred-times.container')));
const DeleteAccountContainer = Loadable(lazy(() => import('../views/preferences/delete-account/containers/delete-account.container')));

const ParticipantManagementList = Loadable(lazy(() => import("../views/settings/participant-management/containers/participant-management-list.container")));
const UserManagementList = Loadable(lazy(() => import("../views/settings/user-management/containers/user-management-list.container")));
const TrialManagementList = Loadable(lazy(() => import("../views/settings/trial-management/containers/trial-management-list.container")));
const CompanyManagementPage = Loadable(lazy(() => import("../views/settings/company-management/containers/company-management-list.container")));
const AuditHistoryPage = Loadable(lazy(() => import("../views/settings/audit-history/containers/audit-history-list.container")));
const EvripathDashboard = Loadable(lazy(() => import("../views/settings/evripath-dashboard/containers/evripath-dashboard.container")));


const FourOhFour = Loadable(lazy(() => import('../views/404')));

const YourTrialsComponent = Loadable(lazy(() => import('../views/participant-portal/your-trials/containers/your-trials.container')));
const FindATrialContainer = Loadable(lazy(() => import('../views/participant-portal/find-a-trial/containers/find-a-trial.container')));
const ParticipantsContainer = Loadable(lazy(() => import('../views/participant-upload/containers/participant-management-list.container')));
const TrialDetailsContainer = Loadable(lazy(() => import('../views/participant-portal/trial-details/containers/trial-details.container')));
const JotformVerifyContainer = Loadable(lazy(() => import('../views/participant-portal/trial-details/containers/jotform-verify.container')));
const JotformFailedContainer = Loadable(lazy(() => import('../views/participant-portal/trial-details/containers/jotform-failed.container')));

const FileLibraryListContainer = Loadable(lazy(() => import('../views/file-library/containers/file-library-list.container')));
const NotificationsContainer = Loadable(lazy(() => import('../views/notifications/containers/notifications-list.container')));

const Landing = Loadable(lazy(() => import('../views/authentication/landing')));
const ExpiredDate = Loadable(lazy(() => import('../views/expired-date')));
const AuthLogin = Loadable(lazy(() => import('../views/authentication/login')));
const Login = Loadable(lazy(() => import('../views/authentication/login/login.component')));
const VerifyOtc = Loadable(lazy(() => import('../views/authentication/login/verify-otc.component')));
const AuthSignUp = Loadable(lazy(() => import('../views/authentication/signup')));
const ResendVerification = Loadable(lazy(() => import('../views/authentication/resend-verification')));
const ForgotPassword = Loadable(lazy(() => import('../views/authentication/forgot-password')));
const ResetPassword = Loadable(lazy(() => import('../views/authentication/reset-password')));

const Register = Loadable(lazy(() => import('../views/authentication/register')));
const Profile = Loadable(lazy(() => import('../views/authentication/profile/auth-profile.component')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {


    const {user} = useAuth();


    const LogOut = () => {
        const {logout} = useAuth();

        const handleLogout = async () => {
            try {
                await logout();
            } catch (err) {
                console.error(err);
            }
        };

        React.useEffect(() => {
            handleLogout();
        });

        return (<React.Fragment/>);
    }


    return (
                <Routes>

                    <Route element={<AuthLayout/>}>
                        <Route exact path="/" element={<PublicGuard component={<Landing />}/>}/>
                        <Route path="/register" element={<RegisteredGuard component={<Register />}/>}/>
                        <Route path="/profile-update" element={<RegisteredGuard component={<Profile />}/>}/>
                        <Route path="/expired-date" element={<PublicGuard component={<ExpiredDate />}/>}/>
                        <Route path="/landing" element={<PublicGuard component={<Landing />}/>}/>
                        <Route path="/login" element={<PublicGuard component={<Login />}/>}/>
                        <Route path="/verify-otc" element={<PublicGuard component={<VerifyOtc />}/>}/>
                        <Route path="/signup" element={<PublicGuard component={<AuthSignUp />}/>}/>
                        <Route path="/resend-verification" element={<PublicGuard component={<ResendVerification />}/>}/>
                        <Route path="/forgot-password" element={<PublicGuard component={<ForgotPassword />}/>}/>
                        <Route path="/reset-password" element={<PublicGuard component={<ResetPassword />}/>}/>
                    </Route>

                    <Route element={<MainLayout />}>
                        <Route path="/logout" element={<AuthGuard component={LogOut} />} />
                        <Route path="/dashboard" element={<AuthGuard component={<Dashboard/>} />} />
                        <Route path="/your-trials" element={<AuthGuard component={<YourTrialsComponent/>} />} />
                        <Route path="/find-a-trial" element={<AuthGuard component={<FindATrialContainer/>} />} />
                        <Route exact path="/trial-details" element={<AuthGuard component={<TrialDetailsContainer/>} />} />
                        <Route exact path="/trial-details/:tid" element={<AuthGuard component={<TrialDetailsContainer/>} />} />
                        <Route path="/trial-details/:tid/request/:rid" element={<AuthGuard component={<TrialDetailsContainer/>} />} />

                        <Route path="/verify-survey/:sid" element={<AuthGuard component={<JotformVerifyContainer/>} />} />
                        <Route path="/failed-survey" element={<AuthGuard component={<JotformFailedContainer/>} />} />


                        <Route path="/notifications" element={<AuthGuard component={<GuardedRoute component={<NotificationsContainer/>} resourceType={UserPermissionTypesEnum.Company} permissions={[UserPermissionsEnum.Read]}/>}/>} />
                        <Route path="/participants" element={<AuthGuard component={<GuardedRoute component={<ParticipantsContainer/>} resourceType={UserPermissionTypesEnum.Company} permissions={[UserPermissionsEnum.Read]}/>}/>} />

                        <Route path="/trial/:tid/site/:sid" element={<AuthGuard component={<GuardedRoute component={<SingleTrial/>} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadParticipants]} parentResourceType={UserPermissionTypesEnum.Location}/>}/>} />
                        <Route path="/trial/:tid/mode/:mode/site/:sid" element={<AuthGuard component={<GuardedRoute component={<SingleTrial/>} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadParticipants]} parentResourceType={UserPermissionTypesEnum.Location}/>}/>} />


                        <Route path="/trial/:tid/mode/:mode" element={<AuthGuard component={<GuardedRoute component={<SingleTrial/>} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadParticipants]}/>}/>} />
                        <Route path="/trial/:tid" element={<AuthGuard component={<GuardedRoute component={<SingleTrial/>} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadParticipants]}/>}/>} />
                        <Route path="/file-library/:tid" element={<AuthGuard component={<GuardedRoute component={<FileLibraryListContainer/>} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadParticipants]}/>}/>} />
                        <Route path="/file-library/:tid/patient/:pid/request/:rid" element={<AuthGuard component={<GuardedRoute component={<FileLibraryListContainer/>} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadParticipants]}/>}/>} />
                        <Route path="/file-library" element={<AuthGuard component={<GuardedRoute component={<FileLibraryListContainer/>} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadParticipants]}/>}/>} />

                        <Route path="/reports/:tid" element={<AuthGuard component={<GuardedRoute component={<Reports/>} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadReports]}/>}/>} />
                        <Route path="/reports" element={<AuthGuard component={<GuardedRoute component={<Reports/>} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadReports]}/>}/>} />

                        <Route path="/settings/company-management" element={<AuthGuard component={<GuardedRoute component={<CompanyManagementPage/>} resourceType={UserPermissionTypesEnum.Company} permissions={[UserPermissionsEnum.RemoveUser]}/>}/>} />
                        <Route path="/settings/audit-history" element={<AuthGuard component={<GuardedRoute component={<AuditHistoryPage/>} resourceType={UserPermissionTypesEnum.Company} permissions={[UserPermissionsEnum.RemoveUser]}/>}/>} />
                        <Route path="/settings/user-management" element={<AuthGuard component={<GuardedRoute component={<UserManagementList/>} resourceType={UserPermissionTypesEnum.Company} permissions={[UserPermissionsEnum.RemoveUser]}/>}/>} />


                        <Route path="/settings/trial-management" element={<AuthGuard component={<GuardedRoute component={<TrialManagementList/>} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.RemoveUser]}/>}/>} />

                        <Route path="/settings/participant-management" element={<AuthGuard component={<GuardedRoute component={<ParticipantManagementList/>} resourceType={UserPermissionTypesEnum.Company} permissions={[UserPermissionsEnum.ReadReports]} resourceId={1}/>}/>} />
                        <Route path="/settings/evripath-dashboard" element={<AuthGuard component={<GuardedRoute component={<EvripathDashboard/>} resourceType={UserPermissionTypesEnum.Company} permissions={[UserPermissionsEnum.ReadReports]} resourceId={1}/>}/>} />



                        {/*<GuardedRoute path="/file-library/:tid/site-id/:sid" component={FileLibraryListContainer} resourceType={UserPermissionTypesEnum.Trial} permissions={[UserPermissionsEnum.ReadParticipants]} parentResourceType={UserPermissionTypesEnum.Location} />*/}


                        <Route exact
                               path="/settings"
                               element={
                                   <>
                                       <PermissionRestricted type={UserPermissionTypesEnum.Trial}
                                                             permissions={[UserPermissionsEnum.RemoveUser]}>
                                           <Navigate to="/settings/trial-management" replace={true}/>
                                       </PermissionRestricted>
                                       <PermissionRestricted type={UserPermissionTypesEnum.Company}
                                                             permissions={[UserPermissionsEnum.RemoveUser]}>
                                           <Navigate to="/settings/company-management" replace={true}/>
                                       </PermissionRestricted>
                                   </>
                               }/>



                        <Route exact
                               path="/preferences"
                               element={<Navigate to="/preferences/your-details" replace={true}/>}/>

                        <Route path="/preferences/your-details" element={<AuthGuard component={<YourDetails/>}/> } />
                        {/*<Route path="/profile-update" element={<ProfileUpdate/>}/>*/}
                        {user && [RoleEnum.PARTICIPANT].includes(user.role) &&
                            <Route path="/preferences/preferred-times" element={<AuthGuard component={<PreferredTimesContainer/>}/>} /> }
                        <Route path="/preferences/notification" element={<AuthGuard component={<NotificationsOverview/>}/>} />

                        <Route path="/preferences/delete-account" element={<AuthGuard component={<DeleteAccountContainer/>}/> } />

                    </Route>

                    <Route path="*" element={<FourOhFour/>}/>
                </Routes>
    );
};

export default MainRoutes;


