import { combineReducers } from 'redux';

// reducer import
import participantDetailsReducer from "../views/trials/patient/state/participant-details.reducer";
import singleTrialReducer from "../views/single-trial/state/single-trial.reducer";
import appReducer from "./appReducer";
import participantDashboardReducer from "../views/participant-portal/state/participant-dashboard.reducer";
import reportReducer from "../views/reports/state/report.reducer";
import trialsReducer from "../views/trials/state/trials.reducer";
import notificationsPreferencesReducer from "../views/preferences/notifications/state/notifications-preferences.reducer";
import preferredTimesReducer from "../views/preferences/preferred-times/state/preferred-times.reducer";
import userManagementReducer from "../views/settings/user-management/state/user-management.reducer";
import trialManagementReducer from "../views/settings/trial-management/state/trial-management.reducer";
import companyManagementReducer from "../views/settings/company-management/state/company-management.reducer";
import deleteAccountReducer from "../views/preferences/delete-account/state/delete-account.reducer";
import trialDetailsReducer from "../views/participant-portal/trial-details/state/trial-detail-reducer";
import fileLibraryReducer from "../views/file-library/state/file-library.reducer";
import participantManagementReducer from '../views/participant-upload/state/participant-management.reducer';
import auditHistoryReducer from '../views/settings/audit-history/state/audit-history.reducer';
import notificationPanelReducer from "../shared/components/notification-panel/state/notification-panel.reducer";
import notificationsReducer from '../views/notifications/state/notifications.reducer';
import patientManagementReducer from "../views/settings/participant-management/state/participant-management.reducer";
import evripathDashboardReducer from "../views/settings/evripath-dashboard/state/evripath-dashboard.reducer";

const reducers = combineReducers({
    app: appReducer,
    participantDetails: participantDetailsReducer,
    singleTrial: singleTrialReducer,
    participantDashboard: participantDashboardReducer,
    report: reportReducer,
    trials: trialsReducer,
    notificationsPreferences: notificationsPreferencesReducer,
    preferredTimes: preferredTimesReducer,
    userManagement: userManagementReducer,
    patientManagement: patientManagementReducer,
    trialManagement: trialManagementReducer,
    companyManagement: companyManagementReducer,
    deleteAccount: deleteAccountReducer,
    trialDetailsReducer: trialDetailsReducer,
    fileLibrary: fileLibraryReducer,
    participantManagement: participantManagementReducer,
    notificationPanel: notificationPanelReducer,
    notifications: notificationsReducer,
    auditHistory: auditHistoryReducer,
    evripathDashboard: evripathDashboardReducer
});

export default reducers;
