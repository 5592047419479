import {useLocation} from 'react-router-dom';
import {useCallback, useEffect, useState} from 'react';
import {EvripathService, ReferralLinkEvent} from "../api/connect";
import {ReferralLinkEventTypeEnum} from "../shared/enums/referral-link-event-type.enum";


/*
 Example URL tracker: https://connectdev.evrima.com.au/register?referralTrackingId=abcde
 */

export function useReferralTracking() {
    const { search } = useLocation();

    const [referralData, setReferralData] = useState(() => {
        const params = new URLSearchParams(search);
        const referralTrackingId = params.get('referralTrackingId') || localStorage.getItem('referralTrackingId');
        return { referralTrackingId };
    });

    useEffect(() => {
        const { referralTrackingId } = referralData;

        if (referralTrackingId && localStorage.getItem("referralTrackingId") !== referralTrackingId) {
            localStorage.setItem("referralTrackingId", referralTrackingId);
        }
    }, [referralData.referralTrackingId]); // Watch only `referralTrackingId`

    // Helper function to track referral data
    const trackReferral = useCallback(
        async (eventType: ReferralLinkEventTypeEnum, participantId: number|null = null, participantCognitoSub: string|null = "") => {
            const { referralTrackingId } = referralData;
            if (referralTrackingId) {
                try {
                    await EvripathService.postApiEvripathAddReferralLinkEvent({
                        participantId,
                        participantCognitoSub,
                        referralTrackingId,
                        referralLinkEventType: eventType as unknown as ReferralLinkEvent,
                    });
                    return !!referralTrackingId;
                } catch (e) {
                    console.error(e);
                    return false;
                }
            }
        },
        [referralData]
    );

    // Helper function to clear referral data
    const clearReferralData = () => {
        localStorage.removeItem('referralTrackingId');
        setReferralData({ referralTrackingId: null });
    };

    return {
        referralData,
        setReferralData,
        trackReferral,
        clearReferralData,
    };
}
